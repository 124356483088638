/*
* HEX to CSS filter
* -----------------
* There are two ways to load a SVG icon into the DOM. 
* To encapsulate it within the `<img>` tag, the CSS `fill` property doesn't work anymore. 
* To overcome this downfall, the CSS `filter` property will be used.
* To generate the filter values, use this site: https://codepen.io/sosuke/pen/Pjoqqp
*/

/*
* Remark: All classes have the format: `htf-<color>`
*
* Remarks: 
* There is a chance that the filter was not successfully able to apply to the `mt-svg`. 
* If that is the case, then add the class within the components scss file. 
* That should solve the bug with the least time needed. The other option is to adjust the values until the color is correct.
*/

.htf-emptystate,
.htf-loadingstate {
  filter: var(--filter-grau-blau);
}

/* var(--blau) */
.htf-blau {
  filter: var(--filter-blau);
}

/* var(--mt-blau) */
.htf-mt-blau {
  filter: var(--filter-mt-blau);
}

/* #31708f */
.htf-info_blau {
  filter: var(--filter-info-blue);
}

/* var(--corn_blume) */
.htf-corn_blume {
  filter: var(--filter-corn-blue);
}

/* var(--regent_grau) */
.htf-regent_grau {
  filter: var(--filter-regent-grau);
}

/* var(--weis) */
.htf-white {
  filter: var(--filter-white);
}

.htf-black {
  filter: var(--filter-black);
}

:root {
  --filter-black: invert(0%);
  --filter-blau: invert(34%) sepia(97%) saturate(2933%) hue-rotate(230deg) brightness(112%) contrast(103%);
  --filter-coral: invert(60%) sepia(19%) saturate(3618%) hue-rotate(322deg) brightness(100%) contrast(114%);
  --filter-corn-blue: invert(86%) sepia(7%) saturate(3016%) hue-rotate(167deg) brightness(99%) contrast(86%);
  --filter-grau-blau: invert(73%) sepia(5%) saturate(152%) hue-rotate(161deg) brightness(93%) contrast(87%);
  --filter-grau-opaque: invert(23%) sepia(9%) saturate(1076%) hue-rotate(169deg) brightness(88%) contrast(87%)
    opacity(50%);
  --filter-grau: invert(40%) sepia(6%) saturate(462%) hue-rotate(169deg) brightness(90%) contrast(87%);
  --filter-gruen: invert(92%) sepia(86%) saturate(1340%) hue-rotate(31deg) brightness(121%) contrast(71%);
  --filter-inactive-link: invert(38%) sepia(19%) saturate(517%) hue-rotate(202deg) brightness(88%) contrast(84%);
  --filter-info-blue: invert(42%) sepia(24%) saturate(1144%) hue-rotate(155deg) brightness(85%) contrast(85%);
  --filter-info-rot: invert(37%) sepia(49%) saturate(1141%) hue-rotate(320deg) brightness(78%) contrast(84%);
  --filter-mt-blau: invert(45%) sepia(94%) saturate(3086%) hue-rotate(172deg) brightness(101%) contrast(101%);
  --filter-mt-darkgrey: invert(26%) sepia(21%) saturate(345%) hue-rotate(169deg) brightness(87%) contrast(98%);
  --filter-mt-error: invert(58%) sepia(36%) saturate(885%) hue-rotate(322deg) brightness(93%) contrast(100%);
  --filter-mt-grau: invert(43%) sepia(4%) saturate(800%) hue-rotate(169deg) brightness(82%) contrast(82%);
  --filter-mt-green: invert(82%) sepia(69%) saturate(476%) hue-rotate(27deg) brightness(92%) contrast(86%);
  --filter-mt-hellgrau: invert(78%) sepia(6%) saturate(270%) hue-rotate(180deg) brightness(88%) contrast(88%);
  --filter-mt-mediumgrey: invert(20%) sepia(13%) saturate(764%) hue-rotate(169deg) brightness(99%) contrast(86%);
  --filter-mt-orange: invert(55%) sepia(52%) saturate(1377%) hue-rotate(322deg) brightness(103%) contrast(119%);
  --filter-mt-rot: invert(43%) sepia(29%) saturate(6084%) hue-rotate(338deg) brightness(104%) contrast(98%);
  --filter-regent-grau: invert(56%) sepia(12%) saturate(439%) hue-rotate(170deg) brightness(101%) contrast(89%);
  --filter-sync-active: invert(69%) sepia(12%) saturate(2388%) hue-rotate(68deg) brightness(84%) contrast(86%);
  --filter-sync-not-set: invert(82%) sepia(44%) saturate(4063%) hue-rotate(357deg) brightness(98%) contrast(106%);
  --filter-sync-outdated: invert(10%) sepia(92%) saturate(6252%) hue-rotate(1deg) brightness(110%) contrast(114%);
  --filter-white: invert(100%);
  --filter-o365: invert(46%) sepia(31%) saturate(5705%) hue-rotate(346deg) brightness(101%) contrast(102%);
}
