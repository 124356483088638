// Common
$primary-font-family: 'OpenSans', sans-serif;
$section-title-font-family: 'Ubuntu-Bold';

$basic-radius: 6px;
$basic-margin: 6px;
$basic-padding: 25px;
$component-padding: 15px;

// Color schemas
$primary-color-main: #e9e9e9;
$primary-color-light: #d9dce5;
$primary-color-400: #eceef4;
$primary-color-dark: #b3b6b8;
$primary-color-contrast: #363f48;

$secondary-color-main: #4d467f;
$secondary-color-dark: #3c3663;

$neutral-color: #fff;
$neutral-color-contrast: $primary-color-contrast;

$background-color: #25204d;
$background-color-contrast: $neutral-color-contrast;

// $accent-color-main: #58bcad;
$accent-color-main: #00ffbe;
$accent-color-dark: var(--blau);
$accent-color-light: #8cefdf;

$validation-error-message-color: #ff7059;
