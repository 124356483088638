/* #region ------------------------Components--------------------- */

mt-icon-button.dropdown-toggle::after,
mt-button.dropdown-toggle::after {
  display: none !important;
}

mt-button {
  .disabled-click-event {
    pointer-events: none;
  }

  .dropdown-toggle {
    height: var(--button-normal-height);
  }
}

mt-svg {
  &.mt-darkgrey {
    filter: var(--filter-mt-darkgrey);
  }

  &.mt-grau {
    filter: var(--filter-mt-grau);
  }

  &.mt-grey {
    filter: var(--filter-mt-hellgrau);
  }

  &.mt-green {
    filter: var(--filter-mt-green);
  }

  &.mt-hellgrau {
    filter: var(--filter-mt-hellgrey);
  }

  &.mt-mediumgrey {
    filter: var(--filter-mt-mediumgrey);
  }

  &.mt-orange {
    filter: var(--filter-mt-orange);
  }

  &.mt-rot {
    filter: var(--filter-coral);
  }

  &.mt-white {
    filter: var(--filter-white);
  }
}

/* #endregion - Components */

/* #region ------------------------Colors--------------------- */

.mt-grey {
  color: var(--grau-blau);

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--grau-blau) !important;
    }
  }
}

.mt-grau {
  color: var(--grau) !important;

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--grau) !important;
    }
  }
}

.mt-green {
  color: var(--gruen);

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--gruen) !important;
    }
  }
}

.mt-mediumgrey {
  color: var(--hellschwarz);

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellschwarz) !important;
    }
  }
}

.mt-darkgrey {
  color: var(--hellschwarz) !important;

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellschwarz) !important;
    }
  }
}

.mt-hellgrau {
  filter: invert(75%) sepia(9%) saturate(279%) hue-rotate(166deg) brightness(87%) contrast(87%);

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellgrau_creme) !important;
    }
  }
}

.mt-orange {
  color: var(--coral) !important;

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--coral) !important;
    }
  }
}

.mt_orange {
  background-color: var(--coral) !important;
  font-size: 18px;
  line-height: 15px;
}

.mt-rot {
  color: var(--coral) !important;

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--coral) !important;
    }
  }
}

.mt_white {
  background-color: var(--weis) !important;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--blau) !important;
  color: var(--blau);
  font-size: 18px;
}

/* #endregion */

/* #region ------------------------Button--------------------- */

.mt_button {
  border-radius: var(--default-border-radius);
  border: none;
  cursor: pointer;
  font-family: 'roboto_light', sans-serif;
  font-size: 14px;
  height: 30px;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 7px 25px;

  &:hover {
    transition: all 0.3s ease 0s;
  }

  svg {
    height: 12px;
    margin-right: 4px;
    width: 12px;
  }
}

.mt-button-border {
  border: 1px solid var(--grau-ccc) !important;
}

/* #region ------------------------Button Color: PRIMARY--------------------- */

.mt_button.mt_primary {
  background-color: var(--blau);
  color: var(--weis);

  &:active {
    background-color: var(--blau);
  }

  &:disabled {
    background-color: var(--mt-blau_disabled);
  }

  &:hover {
    background-color: var(--blau_hover);
  }

  mt-svg {
    filter: var(--filter-white);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--weis);
    }
  }
}

/* #endregion */

/* #region ------------------------Button Color: SECONDARY--------------------- */

.mt_button.mt_secondary {
  background-color: var(--default-btn);
  color: var(--hellschwarz);

  &:active {
    background-color: var(--default-btn);
  }

  &:disabled {
    background-color: var(--default-btn-disabled);
  }

  &:hover {
    background-color: var(--default-btn-hover);
  }

  mt-svg {
    filter: var(--filter-mt-darkgrey);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellschwarz);
    }
  }
}

/* #endregion */

/* #region ------------------------Button Color: DANGER--------------------- */

.mt_button.mt_danger {
  background-color: var(--coral);
  color: var(--weis);

  &:active {
    background-color: green;
  }

  &:disabled {
    background-color: yellow;
  }

  &:hover {
    background-color: purple;
  }

  mt-svg {
    filter: var(--filter-white);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--weis);
    }
  }
}

/* #endregion */

/* #region ------------------------Button Color: PROCEED--------------------- */

.mt_button.mt_proceed {
  background-color: var(--gruen);
  color: var(--hellschwarz);

  &:active {
    background-color: var(--default-btn);
  }

  &:disabled {
    background-color: var(--gruen_hover);
  }

  &:hover {
    background-color: var(--gruen_hover);
  }

  mt-svg {
    filter: var(--filter-mt-darkgrey);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellschwarz);
    }
  }
}

/* #endregion */

/* #region ------------------------Button: LISTNAV--------------------- */

.mt_button.mt_listnav {
  background-color: var(--weis);
  border: 1px solid var(--default-outline-color);
  box-sizing: border-box;
  color: var(--hellschwarz);

  mt-svg {
    filter: var(--filter-mt-darkgrey);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellschwarz);
    }
  }
}

/* #endregion */

/* #region ------------------------Button: SPECIAL1--------------------- */

.mt_button.mt_special1 {
  background-color: var(--weis);
  border: 1px solid var(--default-outline-color);
  box-sizing: border-box;
  color: var(--hellschwarz);

  &:disabled {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover {
    -moz-box-shadow: 0 0 10px 0 var(--free-plan-broder);
    -webkit-box-shadow: 0 0 10px 0 var(--free-plan-broder);
    box-shadow: 0 0 10px 0 var(--free-plan-broder);
  }

  mt-svg {
    filter: var(--filter-mt-darkgrey);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--hellschwarz);
    }
  }
}

/* #endregion */

/* #endregion - Button */

/* #region ------------------------Navigation--------------------- */

.mt-nav-preview-tag {
  background: url('/img/common/PREVIEW.png') no-repeat;
  display: inline-block;
  height: 15px;
  vertical-align: middle;
  width: 52px;
}

/* #endregion */

/* #region ------------------------Menu--------------------- */

/* #region - Menu:body */

.mt-menu-logo-icon {
  display: inline-block;
  height: 40px;
  margin: calc((100% - 40px) / 2);
  vertical-align: middle;
  width: 40px;

  svg {
    height: 40px;
    width: 40px;
  }
}

.mt-menu-icon-wrapper {
  .cognism-signatures {
    &:hover {
      background-color: var(--cogsig-bg) !important;
    }

    &:active {
      background-color: var(--cogsig-bg) !important;
    }
  }

  .mt-menu-icon {
    display: inline-block;
    height: 28px;
    margin: calc((100% - 28px) / 2);
    vertical-align: middle;
    width: 28px;

    svg {
      height: 28px;
      width: 28px;
    }
  }

  .mt-menu-icon_news {
    mt-svg {
      filter: var(--filter-white);
    }

    svg {
      path,
      circle,
      rect,
      polygon {
        fill: var(--weis) !important;

        &.red {
          fill: var(--mt-error) !important;
        }
      }
    }
  }
}

.mt-menu-icon-wrapper-active,
.mt-menu-icon-wrapper:hover {
  background-color: var(--cogsig-bg);
  color: var(--hellschwarz);

  .mt-menu-icon {
    mt-svg {
      filter: var(--filter-blau);
    }

    svg {
      path,
      circle,
      rect,
      polygon {
        fill: var(--blau) !important;

        &.red {
          fill: var(--mt-error) !important;
        }
      }
    }
  }

  .mt-menu-icon_news {
    mt-svg {
      filter: var(--filter-mt-error);
    }
  }
}

.mt-menu-icon-wrapper-active.cognism-signatures {
  background-color: var(--sidebar-expand) !important;

  &:hover {
    background-color: var(--sidebar-expand) !important;
  }

  &:active {
    background-color: var(--sidebar-expand) !important;
  }
}

.mt-menu-icon-wrapper_inactive {
  .mt-menu-icon {
    mt-svg {
      filter: var(--filter-grau);
    }

    svg {
      path,
      circle,
      rect,
      polygon {
        fill: var(--grau) !important;
      }
    }
  }
}

.mt-menu-tooltip-wrapper_menu.settings-tooltip {
  > .arrow {
    display: none;
  }
}

/* #endregion */

/* #region ------------------------Menu: Submenu--------------------- */

.black-svg .mt-blau,
.black-svg .mt-submenu-title_subtitle-wrapper:hover {
  color: var(--grau);

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--grau) !important;
    }
  }
}

.black-svg .mt-submenu-title_subtitle-wrapper:hover mt-svg,
mt-svg.black-svg {
  filter: var(--filter-grau);
}

.mt-submenu-icon {
  display: inline-block;
  height: 100%;
  width: 12px;

  svg {
    height: 12px;
    width: 12px;
  }
}

.mt-submenu-title {
  margin-left: 10px;
}

.mt-submenu-title_subtitle-wrapper {
  color: var(--grau-blau);

  mt-svg {
    filter: var(--filter-mt-hellgrau);
  }

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--grau-blau);
    }
  }
}

.mt-submenu-title_subtitle {
  margin-left: 24px;
}

.mt-menu-tooltip-wrapper.bottom.tooltip {
  opacity: 1;
  padding-right: 30px;

  .tooltip-arrow {
    border-bottom-color: var(--weis);

    &:after {
      box-shadow: none;
    }
  }

  .tooltip-inner {
    background-color: var(--weis);
    padding: 0px;

    .mt-tooltip-container {
      padding: 0px;

      .accounts {
        padding: 20px;
        text-align: left;

        .account-select button {
          background-color: var(--weis);
          border-color: var(--grau-ccc);
          border: 1px solid;
          display: -moz-box;
          display: -moz-flex;
          display: -ms-flexbox;
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          justify-content: space-between;
          outline: none;

          .caret {
            margin: auto 0;
          }
        }

        .small-title {
          color: rgba(52, 50, 51, 0.38);
          font-family: 'roboto_regular', sans-serif;
          font-size: 12px;
          margin-bottom: 10px;
        }

        .text-underline {
          cursor: pointer;
          text-decoration-color: rgba(52, 50, 51, 0.38);
        }
      }

      .logout {
        color: var(--mine_schacht);
        cursor: pointer;
        font-family: 'roboto_regular', sans-serif;
        font-size: 12px;
        padding: 20px;
        text-align: left;
      }

      .tooltip-divider {
        border-top: solid 1px var(--dunkelweis);
        height: 2px;
      }

      .user-desc {
        padding: 20px;

        .profile-image {
          align-self: start;
          padding-right: 15px;

          .img-circle {
            width: 40px;
          }
        }
      }

      .user-data {
        min-width: 200px;
        text-align: left;

        .edit {
          color: var(--blau);
          font-family: 'roboto_regular', sans-serif;
          font-size: 12px;
        }

        .email {
          color: rgba(52, 50, 51, 0.72);
          font-family: 'roboto_regular', sans-serif;
          font-size: 12px;
        }

        .name {
          color: var(--mine_schacht);
          font-family: 'roboto_medium', sans-serif;
          font-size: 14px;
        }
      }
    }
  }
}

.mt-blau,
.mt-submenu-title_subtitle-wrapper:hover {
  color: var(--blau) !important;

  svg {
    path,
    circle,
    rect,
    polygon {
      fill: var(--blau) !important;
    }
  }
}

.mt-submenu-title_subtitle-wrapper:hover mt-svg,
mt-svg.mt-blau {
  /* REMARK: mt-blau */
  /* filter: var(--filter-mt-blau); */
  /* REMARK: cogsig-blau */
  filter: var(--filter-blau);
}

/* #endregion */

/* #region ------------------------Menu: Submenu Sidebar--------------------- */

.mt-submenu-sidebar-inner-wrapper {
  .mt-submenu-sidebar-link-wrapper {
    li.mt-submenu-sidebar-link__inactive {
      * {
        color: var(--blau-inactive) !important;
      }
    }
  }
}

.mt-submenu-sidebar-link-wrapper.cognism-signatures {
  background-color: var(--ec-sidebar-menu-bg) !important;

  li {
    color: var(--weis) !important;

    &:hover {
      mt-svg {
        filter: var(--filter-blau) !important;
      }
    }

    .active {
      * {
        color: var(--blau) !important;
      }
    }
  }

  mt-svg.mt-grey {
    filter: var(--filter-white) !important;
  }

  li.active {
    mt-svg {
      filter: var(--filter-blau) !important;
    }
  }
}

.mt-submenu-sidebar-lock.cognism-signatures {
  mt-svg {
    filter: var(--filter-white) !important;
  }
}

.mt-submenu-sidebar-wrapper {
  background-color: var(--sidebar-expand);
  display: table;
  height: 100vh;
  left: 66px;
  position: fixed;
  top: 0;
  width: 240px;

  li {
    &:hover .show:not(.link__inactive) {
      * {
        color: var(--blau) !important;
      }
    }
  }

  .mt-submenu-sidebar-inner-wrapper {
    height: 100vh;
    margin: 20px;
    user-select: none;

    .mt-submenu-sidebar-link-wrapper {
      list-style: none;
      padding: 0;

      li {
        cursor: pointer;
        font-weight: 500;

        &.active *,
        &:hover .show:not(.link__inactive) * {
          color: var(--blau) !important;

          svg {
            path,
            circle,
            rect,
            polygon {
              color: var(--blau) !important;
            }
          }
        }

        &.mt-submenu-sidebar-link__inactive * {
          color: var(--grau) !important;

          mt-svg {
            filter: var(--filter-grau);
          }

          svg {
            path,
            circle,
            rect,
            polygon {
              fill: var(--grau) !important;
            }
          }
        }

        .mt-submenu-dropdown-arrow {
          float: right;
        }

        .mt-submenu-counter {
          font-weight: 300;
          float: right;
        }
      }
    }

    .mt-submenu-sidebar-lock {
      color: var(--grau) !important;
      cursor: pointer;
      float: right;

      mt-svg {
        filter: var(--filter-white);
        height: 20px;
        width: 20px;
      }

      svg {
        height: 20px;
        width: 20px;

        path,
        circle,
        rect,
        polygon {
          fill: var(--grau) !important;
        }
      }
    }

    .mt-submenu-sidebar-title {
      color: var(--weis);
      display: inline-block;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 40px;
    }
  }
}

.mt-submenu-sidebar-wrapper-trial-area-shown {
  margin-top: 48px;
  height: calc(100vh - 50px);
}

.mt-submenu-sidebar-wrapper_floating {
  -moz-box-shadow: -5px 0 5px 8px rgba(54, 63, 72, 0.75);
  -webkit-box-shadow: -5px 0 5px 8px rgba(54, 63, 72, 0.75);
  box-shadow: -5px 0 5px 8px rgba(54, 63, 72, 0.75);
}

.mt-submenu-sidebar-unlocked.cognism-signatures {
  mt-svg {
    filter: var(--filter-white) !important;
  }
}

/* #endregion */

/* #endregion - Menu */

mt-svg.mt-green {
  filter: var(--filter-mt-green);
}

.mt-mediumgrey {
  color: var(--hellschwarz);
}

.mt-mediumgrey svg path,
.mt-mediumgrey svg circle,
.mt-mediumgrey svg rect,
.mt-mediumgrey svg polygon {
  fill: var(--hellschwarz) !important;
}

mt-svg.mt-mediumgrey {
  filter: var(--filter-mt-mediumgrey);
}

.mt-darkgrey {
  color: var(--hellschwarz) !important;
}

.mt-darkgrey svg path,
.mt-darkgrey svg circle,
.mt-darkgrey svg rect,
.mt-darkgrey svg polygon {
  fill: var(--hellschwarz) !important;
}

mt-svg.mt-darkgrey {
  filter: var(--filter-mt-darkgrey);
}

.mt-grau,
.mt-submenu-sidebar-wrapper .mt-submenu-sidebar-inner-wrapper .mt-submenu-sidebar-lock {
  color: var(--grau) !important;
}

.mt-grau svg path,
.mt-submenu-sidebar-wrapper .mt-submenu-sidebar-inner-wrapper .mt-submenu-sidebar-lock svg path,
.mt-grau svg circle,
.mt-submenu-sidebar-wrapper .mt-submenu-sidebar-inner-wrapper .mt-submenu-sidebar-lock svg circle,
.mt-grau svg rect,
.mt-submenu-sidebar-wrapper .mt-submenu-sidebar-inner-wrapper .mt-submenu-sidebar-lock svg rect,
.mt-grau svg polygon,
.mt-submenu-sidebar-wrapper .mt-submenu-sidebar-inner-wrapper .mt-submenu-sidebar-lock svg polygon {
  fill: var(--grau) !important;
}

mt-svg.mt-grau,
.mt-submenu-sidebar-wrapper .mt-submenu-sidebar-inner-wrapper .mt-submenu-sidebar-lock mt-svg {
  filter: var(--filter-mt-grau);
}

.mt-hellgrau {
  filter: invert(75%) sepia(9%) saturate(279%) hue-rotate(166deg) brightness(87%) contrast(87%);
}

.mt-hellgrau svg path,
.mt-hellgrau svg circle,
.mt-hellgrau svg rect,
.mt-hellgrau svg polygon {
  fill: var(--hellgrau_creme) !important;
}

mt-svg.mt-hellgrau {
  filter: var(--filter-mt-hellgrey);
}

.mt-rot {
  color: var(--rot) !important;
}

.mt-rot svg path,
.mt-rot svg circle,
.mt-rot svg rect,
.mt-rot svg polygon {
  fill: var(--rot) !important;
}

mt-svg.mt-rot {
  filter: var(--filter-mt-rot);
}

.mt-pard {
  color: var(--pardot) !important;
}

.mt-pard svg path,
.mt-pard svg circle,
.mt-pard svg rect,
.mt-pard svg polygon {
  fill: var(--pardot) !important;
}

mt-svg.mt-pard {
  filter: var(--filter-mt-pard);
}

.mt-orange {
  color: var(--coral) !important;
}

.mt-orange svg path,
.mt-orange svg circle,
.mt-orange svg rect,
.mt-orange svg polygon {
  fill: var(--coral) !important;
}

mt-svg.mt-orange {
  filter: var(--filter-mt-orange);
}
/* #region ------------------------Dropdown--------------------- */

.mt-dropdown-menu > li {
  color: var(--hellschwarz);
  font-size: 16px;
  user-select: none;

  &:hover,
  :hover {
    background-color: var(--blau_hover) !important;
    color: var(--weis) !important;

    mt-svg {
      filter: var(--filter-white);
    }

    svg {
      path,
      circle,
      rect,
      polygon {
        fill: var(--weis);
      }
    }
  }

  mt-svg {
    display: inline-block;
    filter: var(--filter-mt-darkgrey);
  }

  svg {
    height: 12px;
    width: 12px;

    path,
    circle,
    rect,
    polygon {
      display: inline-block;
      fill: var(--hellschwarz);
    }
  }
}

.mt_dropdown {
  border-radius: var(--default-border-radius);
  border: none;
  cursor: pointer;
  font-family: 'roboto_light', sans-serif;
  font-size: 14px;
  height: 30px;
  letter-spacing: 0.5px;
  line-height: 1;
  padding-left: 25px;

  normal {
    line-height: 1;
    margin: auto 5px auto auto;
  }

  svg {
    height: 8px;
    margin-right: 0 !important;
    width: 8px;
  }
}

/* #endregion */

/* #region ------------------------Grid--------------------- */

.mt-flex-grid-row,
.mt-flex-grid-row_column {
  -ms-flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.mt-flex-grid-row_column {
  flex-direction: column;
}

.mt-flex-grid-order-first {
  -ms-flex-order: -1;
  order: -1;
}

.mt-flex-grid-order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.mt-flex-grid-order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.mt-flex-grid-order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.mt-flex-grid-order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.mt-flex-grid-order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.mt-flex-grid-order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.mt-flex-grid-order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.mt-flex-grid-order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.mt-flex-grid-order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.mt-flex-grid-order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.mt-flex-grid-order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.mt-flex-grid-order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.mt-flex-grid-order-last {
  -ms-flex-order: 13;
  order: 13;
}

/* #endregion */

/* #region ------------------------MODAL--------------------- */

.mt_modal {
  .inner-header {
    border-bottom: 0;
    color: var(--hellschwarz);
    font-family: 'roboto_regular', sans-serif;
    line-height: 1.11;
    margin: 0;
    padding: 30px 25px 0 25px;

    .title {
      font-size: 18px;
    }

    .title-big {
      font-size: 24px;
    }

    .title-bold {
      font-family: 'roboto_bold', sans-serif;
    }
  }

  .inner-body {
    .inner-body-header {
      color: var(--blau);
      font-family: 'roboto_bold', sans-serif;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
}

/* #endregion */

/* ------------------------POSITIONS--------------------- */

.mt_left {
  margin-right: 10px;
}

.mt-opacity-50 {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

/* OVERWRITE TO FIT OUR STYLE */
.card-header {
  background-color: var(--dunkelweis);
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: var(--hellschwarz);
  font-family: 'roboto_light', sans-serif;
  font-size: 19px;
  padding: 18px 25px;
}

.mt-cg-busy-wrapper {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
}

@media (min-width: 768px) {
  .trialareashown .mt-cg-busy-wrapper {
    top: 50px;
  }
}

@media (max-width: 768px) {
  .trialareashown .mt-cg-busy-wrapper {
    top: 120px;
  }
}
