@import './variables';
@import './common.scss';

form {
  display: flex;
  flex-direction: column;
  gap: $basic-margin * 2;

  .form-section {
    display: flex;
    flex-direction: column;
    gap: $basic-margin * 2;
  }

  & h5.section-title {
    color: $accent-color-main;
    margin-top: $basic-margin * 2 !important;
    font-family: $section-title-font-family;
  }

  .form-field {
    background-color: $primary-color-main;
    padding: $basic-padding * 0.4;
    border-radius: $basic-radius;
    margin-bottom: 12px;
    margin-right: 12px;

    label {
      font-size: 0.7rem;
      display: block;
    }

    input,
    select {
      box-sizing: border-box;
      border: 0;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      width: 100%;
      height: 100%;
    }

    select {
      margin-left: -4px;
    }
  }

  .action-container {
    margin-bottom: $basic-margin * 2;
    margin-top: $basic-margin * 2;
    text-align: center;
    display: flex;
    gap: $basic-margin * 2;
    flex-direction: column;
  }
}
