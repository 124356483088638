/* Labels
   ========================================================================== */
// Colors

.label-default {
  @include label-variant($label-default-bg);
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-secondary {
  @include badge-variant($label-secondary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}

.label {
  font-weight: normal;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-block;
  vertical-align: middle;
}

.label-white {
  border: solid 1px #dedede;
  color: #5e5e5e;
}

.label-block {
  display: block;
  text-align: center;
}

.label-block + .label-block {
  margin-top: 5px;
}

.user-content .label + .label {
  margin-left: 8px;
}
