button {
  -moz-user-select: none;
  -ms-touch-action: manipulation;
  -ms-user-select: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  background-image: none;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: var(--button-normal-height);
  line-height: 1.4286;
  margin-bottom: 0;
  outline: 1px solid transparent;
  padding: 6px 12px;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  > span {
    font-size: 14px;
  }
}

.mt-button-primary,
.mt-button-primary:focus {
  background-color: var(--blau);
  border: none;
  box-shadow: none;
  color: var(--weis);
}

.mt-button-primary:hover {
  color: var(--weis);
  background-color: var(--blau_hover);
}

.mt-button-primary:disabled {
  background-color: var(--blau_disabled);
  cursor: default;
}

.mt-button-secondary,
.mt-button-secondary:focus {
  background-color: var(--default-btn);
  border: none;
  box-shadow: none;
  color: var(--hellschwarz);
}

.mt-button-secondary:hover {
  background-color: var(--default-btn-hover);
}

/* White */
.mt-button-white {
  background-color: var(--weis);
  border: none;
  box-shadow: none;
  color: var(--mine_schacht);
  font-weight: 500;
}

.mt-button-white:hover {
  background-color: var(--weis);
  border-color: transparent;
}
