// ----- COLORS ----- //
:root {
  --advise-plan: #78818b;
  --black-invisble: rgba(0, 0, 0, 0.075);
  --black: #000000;
  --blau_disabled: #b2b0ff;
  --blau_hover: #4e3cbb;
  --blau_lighter: #4b4691;
  --blau-inactive: #7280b7;
  --blau: #6a67ff;
  --blue-box-bg: #cfceff;
  --blue-slider-focus: rgba(103, 58, 183, 0.2);
  --blue-slider-hover: rgba(103, 58, 183, 0.05);
  --border-color: #dddddd;
  --botticelli: #d4cee2;
  --box-shadow-01: #0000001f;
  --box-shadow-24: #00000024;
  --box-shadow-33: #00000033;
  --box-shadow-dark: rgba(22, 22, 46, 0.5);
  --bright-gray: #e9e9e9;
  --btn-bg-dark: #16162e;
  --btn-text: #ffffff;
  --catskill_weis: #e9eff4;
  --cogsig-bg: #374785;
  --connected-admin-dropdown-border: #ccc;
  --connected-admin-email: rgba(52, 50, 51, 0.72);
  --connected-admin-view-all: rgba(52, 50, 51, 0.38);
  --coral: #ff7059;
  --corn_blume: #96d2eb;
  --dark-bg: #2b396d;
  --dark-gray: #696969;
  --dark-gunmetal: #212529;
  --deactive-color: #e7c6c6;
  --default-btn-disabled: #e6ecf2;
  --default-btn-hover: #c0d2e1;
  --default-btn: #dfe7ee;
  --default-outline-color: #adbac2;
  --disabled-green: #a6ca108f;
  --domains-tag-shadow-color: #d3d3d3;
  --dunkelblau_schwarz: #333447;
  --dunkelblau: #2d4f61;
  --dunkelcoral: #c84732;
  --dunkelgrau_hell: #2f3032;
  --dunkelgrau: #27272a;
  --dunkelgruen_hover: #629023;
  --dunkelgruen: #6c9f25;
  --dunkelweis: #e2ebf0;
  --elephant: #2c353c;
  --error-step: #fc5242;
  --error-text: #aa2317;
  --free-plan-broder: rgba(54, 63, 72, 0.2);
  --gated-box-shadow: rgba(255, 255, 255, 1);
  --gated-statistics-column: #9ca4ab;
  --geist: #c7cdd4;
  --google-blue-color: #196fe2;
  --grau-999: #999999;
  --grau-blau: #b6babd;
  --grau-ccc: #cccccc;
  --grau: #5d6267;
  --grau74: #747474;
  --gray-shade: #929da9;
  --gruen_hover: #8bc53b;
  --gruen: #9bdb42;
  --hellblau_grau: #b6c5cd;
  --hellgrau_creme: #a8acb3;
  --hellgrau_tan: #a6a9ab;
  --hellgrau: #909eac;
  --hellrot_liste: #faecea;
  --hellrot: #f3aeb0;
  --hellschwarz: #363f48;
  --hellweis: #cbcdd0;
  --hex-gray: #e5e5e5;
  --inactive-color: #ce3500;
  --inactive-link: #5e5e76;
  --info-box-color: #31708f;
  --info-dot: #ff6249;
  --light-gray: #8886;
  --light-secong-bg: #2b396d80;
  --line-graph-footer-color: #282850;
  --line-graph-section-color: #202040;
  --login_btn_text: #fafafa;
  --login_text: #25204d;
  --marketo: #5c4c9f;
  --mine_schacht: #333333;
  --modal_close_icon_filter: var(--filter-grau-blau);
  --modal_close_icon: var(--grau-blau);
  --mowe_grau: #9da9b4;
  --mt-blau_disabled: #86d0f0;
  --mt-blau_hover: #008ac6;
  --mt-blau: #009fe4;
  --mt-botticelli: #cfd9e4;
  --mt-error: #ee7658;
  --no_mobile_support: #262351;
  --o365-icon: #ff5722;
  --o365-orange-color: #ec4c23;
  --off_rot: #88271e;
  --on_gruen: #518c00;
  --orange: #ff7a59;
  --overlay-bg: rgba(255, 255, 255, 0.55);
  --pardot: #1798c1;
  --popover-arrow-shadow: rgba(0, 0, 0, 0.3);
  --progressbar_green: #b8eb4b;
  --purple: #3b39a6;
  --regent_grau: #8593a2;
  --rot_hover: #c84732;
  --rot: #fc5140;
  --schwarzer_squeeze: #f2f6fa;
  --selected-banner-border: rgba(0, 0, 0, 0.25);
  --selected-signature-status: #85ae24;
  --sidebar-expand: #282850;
  --sidebar-header: #16162e;
  --signature-title-color: #45474a;
  --skandal: #d2effa;
  --slider-shadow: rgba(50, 50, 93, 0.1);
  --svg-active-color: var(--blau);
  --svg-color: var(--weis);
  --switch-border-color: #dfdfdf;
  --switch-box-shadow: rgb(0 0 0 / 40%);
  --sync-active: #47b540;
  --sync-not-set: #ffb400;
  --sync-outdated: #ff0000;
  --text-danger-error-message: #a94442;
  --toolbar-icon-hover: #7f8286;
  --toolbar-icon: #9fa3a7;
  --top-info-bar-text: #16162e;
  --top-info-bar: #b9eb4d;
  --weis_transparent: rgba(255, 255, 255, 0.4);
  --preview-panel-border: #d9dce5;
  --svg-color: var(--weis);
  --svg-active-color: var(--blau);
  --primary-color-light: #d9dce5;
  --primary-color-400: #eceef4;
  --preview-border: #dee2e6;
  --weis-gray: #adadad;
  --weis: #ffffff;

  // Used for only enduser cockpit
  --ec-default-color: var(--blau);
  --ec-headline: var(--weis);
  --ec-sidebar-menu-bg: var(--sidebar-expand);
  --ec-main-bg-light: var(--bs-body-bg);
  --ec-main-bg-dark: var(--dark-bg);
  --ec-rebrand-wizard-bg: var(--weis);
  --ec-black-color: var(--black);
  --ec-dunkelweis: var(--dunkelweis);
  --ec-hellschwarz: var(--hellschwarz);
}

/* TODO: Determine which approach to use, SCSS or CSS */
//Farben
$blau: #009fe4;
$blau_disabled: #86d0f0;
$blau_hover: #008ac6;
$botticelli: #cfd9e4;
$btn-bg-dark: #16162e;
$btn-text: #ffffff;
$catskill_weis: #e9eff4;
$cogsig-bg: #374785;
$coral: #ff7059;
$corn_blume: #96d2eb;
$dunkelblau: #2d4f61;
$dunkelblau_schwarz: #333447;
$dunkelcoral: #c84732;
$dunkelgrau: #27272a;
$dunkelgrau_hell: #2f3032;
$dunkelgruen: #6c9f25;
$dunkelgruen_hover: #629023;
$dunkelweis: #e2ebf0;
$geist: #c7cdd4;
$grau: #5d6267;
$grau74: #747474;
$gruen: #9bdb42;
$gruen_hover: #8bc53b;
$header: #ffffff;
$hellblau_grau: #b6c5cd;
$hellgrau: #909eac;
$hellgrau_creme: #a8acb3;
$hellgrau_tan: #a6a9ab;
$hellrot: #f3aeb0;
$hellrot_liste: #faecea;
$hellschwarz: #363f48;
$hellweis: #cbcdd0;
$info-dot: #ff6249;
$mine_schacht: #333333;
$modal_close_icon: var(--grau-blau);
$modal_close_icon_filter: var(--filter-grau-blau);
$mowe_grau: #9da9b4;
$off_rot: #88271e;
$on_gruen: #518c00;
$orange: #ff7a59;
$regent_grau: #8593a2;
$regent_grau2: #909eac;
$rot: #fc5242;
$schwarzer_squeeze: #f2f6fa;
$sidebar-expand: #282850;
$sidebar-header: #16162e;
$skandal: #d2effa;
$top-info-bar: #b9eb4d;
$top-info-bar-text: #16162e;
$weis: #ffffff;
$weis-transparent: rgba(255, 255, 255, 0.4);