$break-small: 800px;
$external-admin-area-height: 62px;
$external-admin-height-sm: 132px;
$navi-height: 60px;
$screen-md-min: 992px;
$screen-sm-min: 768px;
$screen-lg-min: 1200px;
$trial-area-height: 50px;
$trial-area-height-sm: 120px;

:root {
  --button-normal-height: 32px;
}
