/* Base
   ========================================================================== */

.page-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
}

.page-container.horizontal-menu {
  padding-top: 60px;
}

.main-container {
  min-width: 941px;
  position: relative;
  display: table-cell; // needed for the element to wrap the whole content
  width: 100%;
  vertical-align: top;
}

.main-header {
  padding: 10px;
  border-bottom: solid 1px #e3e1e1;
}

.header-secondary {
  padding: 20px 10px 10px;
}
