@import './variables';

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  // TODO: Make sure to ask for other formats so we support other browsers
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: url('../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/karla/Karla-Regular.ttf') format('truetype');
}

html,
body,
app-root,
app-generator {
  height: 100%;
  display: block;
}

//p {
//  margin: 0;
//}

body {
  // background-color: $background-color;
  // font-family: $primary-font-family;
  margin: 0;
  padding: 0;
  position: relative;
}

h5 {
  margin-bottom: $basic-margin !important;
  margin-top: 0 !important;
}

h4 {
  margin-bottom: $basic-margin !important;
  margin-top: 0 !important;
  font-weight: bold;
}

h4.afd {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-direction: alternate;
}

h5.bfd {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-direction: alternate;
}

// LINKS/BUTTONS

.btn {
  font-family: $primary-font-family;
  color: $neutral-color;
  cursor: pointer;
  font-size: 1rem;
  padding: $component-padding;
  border: 0;
  transition: all 0.5s;
  border-radius: $basic-radius;
  width: 100% !important;
  box-sizing: border-box;
  text-decoration: none;

  &.primary {
    background: $accent-color-main;

    &:hover {
      background: $accent-color-dark;
    }
  }

  &.secondary {
    background: $secondary-color-main;

    &:hover {
      background: $secondary-color-dark;
    }
  }

  &.accent {
    background: $background-color;
    border-color: $neutral-color;
    border-width: 1px;
    border-style: solid;
  }

  &.neutral {
    background: $neutral-color;
    border-color: $neutral-color;
    color: $background-color;
    border-width: 1px;
    border-style: solid;
  }
}

body.modal-open {
  overflow: hidden;

  .scrollbar-control {
    display: none;
  }

  .ng-scroll-viewport {
    overflow-y: hidden !important;
  }

  .modal {
    .scrollbar-control {
      display: flex;
    }

    .ng-scroll-viewport {
      overflow-y: auto !important;
    }
  }
}
